<script>
import DataTableCustomFilter from '@/components/DataTableCustomFilter'

export default {
  name: 'DataTableTransportationDocCustomFilter',
  extends: DataTableCustomFilter,
  data() {
    return {
      nameFilter: this.$i18n.t('transportationCode'),
      store: 'transportationDocumentStore',
      objectKey: 'code',
    }
  },
}
</script>
